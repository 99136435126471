.header {
  padding: 20px 50px 20px 50px;
  border-bottom: 1px solid #ccc;
}

.main {
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.container-home-x {
  min-width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
}

.container-home-x .button-group-home-x {
  width: 68%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  transition: opacity 0.5s ease-in-out;
  gap: 2rem;
  margin: 0 auto;
  row-gap: 15px;
}

.container-home-x .button-group-home-x.visible {
  opacity: 1;
}

.btn-homexpage {
  padding: 0 !important;
  gap: 10px;
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
  cursor: pointer;
}

.btn-homexpage img {
  width: 6rem;
}

.item-button-home-x {
  justify-content: start;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.title-button-home-x {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 3rem;
  height: 4em;
}

.btn-homexpage:hover ~ .title-button {
  color: rgb(6, 245, 34);
}

.item-button-home-x img:hover {
  opacity: 0.5;
}

.toggle-language {
  align-self: center;
  width: 150px;
}

.icon-button {
  padding-right: 25px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;

  .icon {
    color: white;
    font-size: 30px;
    &:hover {
      color: #b3abab;
    }
  }
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3.25rem;
}

.filter-buttons button {
  border: 1px solid #ffffff80;
  border-radius: 4rem;
  background: transparent;
  color: white;
  padding: 12px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-buttons button:hover {
  background: white;
  transform: scale(1.05);
}

.filter-buttons button.active {
  background: #ffffff33;
  transform: scale(0.95);
  border: 1px solid #ffffff;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transform: translateY(-35px);
  padding: 15px 0;
}

.btn-pagination {
  width: 20px;
  height: 5px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease width;
  background: #ccc;
}

.btn-pagination.active {
  width: 55px;
  cursor: auto;
  background: #ffffff;
}

.pagination-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 2rem;
  .prev-button,
  .next-button {
    border: none;
    cursor: pointer;
    font-size: 70px;
    color: #fff;
  }
}

.w-148 {
  width: 148px;
}

.logo-xmed {
  width: 148px;
}

.app-name {
  font-size: 32px;
  margin-bottom: 40px;
}

.fixed-bottom-10 {
  bottom: 10px;
}

@media (max-width: 1919px) {
  .logo-xmed {
    width: 90px;
  }
  .btn-homexpage {
    width: 7rem;
    height: 7rem;
  }
  .container-home-x .button-group-home-x {
    gap: 1rem;
    width: 63%;
    row-gap: 0.6vw;
  }
  .app-name {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .filter-buttons {
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .filter-buttons button {
    padding: 8px 15px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .btn-homexpage img {
    width: 4rem;
  }
  .item-button-home-x {
    gap: 0.8rem;
  }
  .title-button-home-x {
    font-size: 1.2rem;
    line-height: 2rem;
    height: 4em;
  }
  .pagination-buttons {
    .prev-button,
    .next-button {
      font-size: 50px;
    }
  }
  .pagination-dots {
    padding: 10px 0;
  }
}

@media (max-width: 1024px) {
  .container-home-x .button-group-home-x {
    gap: 1rem;
    width: 90%;
    row-gap: 6rem;
  }
  .container-home-x .button-group-home-x {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .container-home-x {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .container-home-x .button-group-home-x {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    row-gap: 4rem;
    .pagination-buttons {
      width: 100%;
      transform: translateY(-50%);
      padding: 0;
    }
  }
}

@media (max-width: 480px) {
  .container-home-x {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .container-home-x .button-group-home-x {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    row-gap: 4rem;
  }
  .pagination-buttons {
    width: 100%;
    transform: translateY(-50%);
    padding: 0;
    .prev-button,
    .next-button {
      font-size: 40px;
    }
  }
}
