.hyperlink {
  color: #008cba;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.height-29 .css-5asp8r-control {
  height: 29px;
  min-height: 29px;
}

.autocomplete-custom-renderform {
  span {
      &+div {
          display: flex;
          // height: 32px;
          align-items: center;
      }
  }
  &>div {
      padding-right: 0;
      border-radius: 3px;
      border-color: #b0b0b0;
  }

  &.rounded-0 > div {
      border-radius: 0;
  }
  &.radius > div {
      border-radius: 3px;
      border-color: #b0b0b0;
  }
}
.text-field-label-down-line {
  display: block;
  align-items: center;
}

.text-title{
  color: #3C78D8;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  &-icon{
    font-weight: bold;
    font-size: 20px;
  }
}