@import "variable";

.container-page {
    padding: 12px 8px;
    height: 100%;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinners {
    -moz-appearance: textfield;
    appearance: textfield;
}

.text-readOnly {
    background-color: $color-disabled !important;
    color: $color-text-table !important;

    &::placeholder {
        color: $color-placeholder;
    }
}

.customs-input {
    height: 32px;
    font-size: 14px;
    // border-color: $royal-blue !important;
    border-color: $color-silver !important;
    border-radius: 6px;

    &:disabled {
        border-color: $royal-blue !important;
    }

    &::placeholder {
        color: $color-placeholder;
        font-size: 13px;
    }
}

textarea.customs-input {
    height: unset;
    min-height: auto;
}

.h-auto-input {
    .customs-input {
        height: auto;
    }
}

.text-lable-input {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
}

.is-invalid {
    border-color: #f1416c !important
}

.field-tooltip-error {
    color: var(--kt-text-danger);
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .1);
}


.ac-is-invalid {

    ~.invalid-feedback {
        display: block;
    }

    >div {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
}

.svg-icon-filter {
    svg {
        width: 21px !important;
        height: 21px !important;
    }
    color: inherit !important;
}

.box-shadow-menu {
    box-shadow: -1px 0px 0px 0px $color-border-table inset !important;
}

input[type="checkbox"] {
    accent-color: var(--kt-primary) !important;
}

.background-image-none {
    background-image: none !important;
}

.rect-img-container {
    width: 100%;
    height: 100%;
}

.rect-img-container::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.rect-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-form-check {
    align-items: flex-start !important;
    margin-top: 26px;

    input {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    label {
        flex: 1
    }

    .form-check-input:checked~.form-check-label::before {
        background-color: $color-gray !important;
        border-color: $color-gray !important;
    }

    .form-check-input:indeterminate~.form-check-label::before {
        background-color: $color-gray !important;
        border-color: $color-gray !important;
    }

    .form-check-input[disabled]~.form-check-label,
    .form-check-input:disabled~.form-check-label {
        opacity: 0.8;
    }
}

.screen-h-grow {
    background-color: $color-white;
    height: calc(100vh - 48px);
    overflow-y: auto;
    overflow-x: hidden;
}

.z-50 {
    z-index: 50;
}

.gap-col-modal {
    row-gap: 0.6rem;
}

.modal.modal-size-custom {
    max-height: 100vh;

    .modal-xl {
        --bs-modal-width: 90% !important;
    }

    .modal-header,
    .modal-body {
        padding: 12px;
    }


}

.bg-transparent {
    background: transparent !important;
}

.grid {
    display: grid;

    &.grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    &.grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }

    &.gap-rem-1\.2 {
        gap: 1.2rem;
    }

    .col-span-6 {
        grid-column: span 6 / span 6;
    }

    .col-span-5 {
        grid-column: span 5 / span 5;
    }

    .col-span-4 {
        grid-column: span 4 / span 4;
    }

    .col-span-3 {
        grid-column: span 3 / span 3;
    }

    .col-span-2 {
        grid-column: span 2 / span 2;
    }

    .col-span-1 {
        grid-column: span 1 / span 1;
    }
}
.grid-cols-4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}
.text-xl {
    font-size: 1.25rem;
}

.h-full {
    height: 100%;
}

.h-1\/2 {
    height: 50% !important;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.hidden {
    display: none !important;
}

.min-h-full {
    min-height: 100%;
}

.screen-h-grow-116 {
    background-color: $color-white;
    height: calc(100vh - 116px);
    overflow-y: auto;
    overflow-x: hidden;
}

.table-modal-h {
    .table-responsive {
        height: calc(100vh - 290px) !important;
    }
}

.bg-orange {
    background-color: $color-orange;
}

.bg-orange-dark {
    background-color: $color-dark-orange;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-5xl {
    border-radius: 2.5rem;
}

.fw-500 {
    font-weight: 500 !important;
}

.svg-icon-md {
    height: 1.5rem !important;
    width: 1.5rem !important;
}

.btn-reset {
    outline: none;
    background-color: transparent;
    border: none;
}

.btn.btn-primary:disabled .svg-icon {
    color: $color-dark-blur-1 !important;
}

.w-1\/2 {
    width: 50% !important;
}

.sub-title {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 0;
    padding-top: 0.7rem;
}

@function hexToRgba($hex, $alpha) {
    $red: red($hex);
    $green: green($hex);
    $blue: blue($hex);
  
    @return rgba($red, $green, $blue, $alpha);
}

.tab-vertical {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 3px;
        background-color: $color-border-table;
        top: 0;
        right: 0;
    }

    .show > .nav-link,
	.nav-link {
        padding: 0.75rem 1rem ;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        cursor: pointer;
        &.active {
            background-color: hexToRgba($color-steel-blue, 0.15);
            color: $color-bright-cyan;
            font-weight: 600;
            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                background-color: $color-steel-blue;
                top: 0;
                right: 0;
            }
        }
    }
}

.bg-zinc-100 {
    background-color: $color-zinc-100;
}

.breadcrumb-title {
    color: var(--kt-primary) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.border-none {
    border: none !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.sub-title-form {
    font-size: 1.5rem;
    font-weight: 600;
    color: $color-bright-cyan;
    padding: 8px 0;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.unti-icon{
    margin-bottom: 5px !important;
    padding-left: 2px !important;
}

.inline-block {
    display: inline-block !important;
}
.z-4 {
    z-index: 4 !important;
}

.leading-1\.5 {
    line-height: 1.5;
}

.status {
    display: inline-block;
    border-radius: 2.5rem;
    color: white;
    padding: 2px 8px;
    width: 130px;
}

.w-max-content {
    width: max-content !important;
}

.subMenu {
    &:hover .subMenu-item {
        display: block;
        transform: translateY(-7px);
        opacity: 1;
        pointer-events: auto;
    }

    &:hover .icon-arrow {
        transform: rotate(180deg) scale(1.2);
    }


    .subMenu-item {
        display: block;
        position: fixed;
        padding: 6px;
        color: white;
        z-index: 100;
        background-color: $color-bright-cyan;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        border-radius: 6px;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-30px);
        transition: transform 0.5s ease, opacity 0.5s ease;
        min-width: 180px !important;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: -20px;
            display: block;
            width: 40px;
            height: 33px;
        }

        svg {
            width: 18px !important;
            height: 18px !important;
        }
    }

    .icon-arrow {
        transform: rotate(270deg);
        color: white;
        margin-right: 2px;
        font-size: 10px;
        transition: transform 0.3s ease;

        svg {
            width: 13px;
            height: 13px;
        }
    }
}

.border-b-2 {
    border-width: 2px !important;
}

.text-left {
    text-align: left;
}

.autocomplete-custom {

    [aria-disabled="true"] {
        background-color: #eff2f5;
        pointer-events: all;
        border-color: #0086d3 !important;
        cursor: not-allowed;

        .css-olqui2-singleValue {
            color: black;
        }
    }

}

.custom-list-data {
    table {
        tbody {
            font-weight: 400 !important;
        }
    }
};

.custom-text-validator {
    flex-direction: column;
    .text-lable-input {
        display: flex;
        font-size: 16px;
        
        .color-red {
          color: red;
          font-size: 0.8em;
        }
    }
}