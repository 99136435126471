.manager {
  height: 100% !important;

  .role-auth {
    padding-top: 10px;

    .table-custom-role {
      width: 100%;

      .table-responsive {
        max-height: calc(100vh - 244px) !important;
      }
    }

    .table-custom-auth {
      width: 100%;

      .table-responsive {
        max-height: calc(100vh - 264px) !important;
      }
    }
  }
}

.relative {
  position: relative;
  i {
    position: absolute;
    font-size: 18px;
    left: 3px;
    cursor: pointer;
    color: var(--kt-primary);
  }
}

.list-permissions {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.list-permissions-item {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.vertical-lines {
  display: block;
  height: calc(100% - 43px);
  border-left: 1px solid var(--kt-primary);
  position: absolute;
  top: 19px;
  left: 38px;
}

.tree-permission {
  width: calc(100% - 40px);
  overflow: auto;
  position: relative;
  .row {
    min-width: 1280px;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: 0.75rem;
    cursor: pointer;
    border: 1px solid var(--kt-primary);
    width: 18px;
    height: 18px;
  }
}

.form-check-label {
  color: #181c32;
}

.vertical-lines-root-admin {
  &::before {
    content: "";
    display: block;
    height: 44px;
    border-left: 1px solid var(--kt-primary);
    position: absolute;
    top: 28px;
    left: 21px;
  }
}

// .vertical-lines-icon-button {
//     &::before {
//         content: "";
//         display: block;
//         border-left: 1px solid var(--kt-primary);
//         position: absolute;
//         height: calc(100% - 41px);
//         top: 20px;
//         left: 20px;
//     }
// }

.per-item {
  left: 50px;
  top: 17px;
}

.horizontal-line {
  &::after {
    content: "";
    display: block;
    border-top: 1px solid var(--kt-primary);
    position: relative;
    top: -11px;
    left: -45px;
    width: 20px;
  }
}

.tree-permission > .relative {
  margin-left: 40px;
}

.vertical-lines:not(.per-item) {
  left: 49px;
  top: 18px;
}

.roles-dialog {
  .modal-body {
    height: calc(100vh - 160px);
    overflow-y: auto;
  }
}
.modal-header {
  background: white;
  padding: 10px 24px !important;

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    color: #1a5e83 !important;
    text-transform: uppercase;
  }

  .btn-close {
    filter: initial;
    background-size: 12px;
  }
}

.modal-paraclinical {
  display: flex !important;
  .modal-dialog {
    min-width: 70%;
    margin: auto !important;
  }
}

.modal-service {
  display: flex !important;
  .modal-dialog {
    min-width: 86%;
    margin: auto !important;
  }
  .modal-content {
    // height: calc(100vh - 126px);
    overflow: hidden;
    position: relative;
    .tab-content {
      overflow-y: auto;
      height: 469px;
      padding: 11px;
      .tab-pane {
        padding-top: 10px;
        .form-check-label {
          max-width: 183px !important;
        }
        .width-25 {
          width: 24.3% !important;
        }
      }
    }
  }
}

.modal-medicalEquipment {
  .modal-body {
    height: calc(100vh - 177px);
    overflow-y: auto;
  }
}

.table-toolbar {
  @media (min-width: 1400px) {
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 30%;
    }
  }
  @media (min-width: 1400px) {
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 70%;
    }
  }
}

.table-toolbar {
  @media (min-width: 1200px) {
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 30%;
    }
  }
  @media (min-width: 1200px) {
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 70%;
    }
  }
}

.table-indicator {
  height: 373px;
}

.modal-package {
  display: flex !important;
  .modal-dialog {
    min-width: 86%;
    margin: auto !important;
  }
  .modal-content {
    overflow: hidden;
    position: relative;
    .modal-body {
      height: calc(100vh - 200px);
      .tab-content {
        overflow-y: auto;
        height: 469px;
        padding: 11px;
        .tab-pane {
          padding-top: 10px;
          .form-check-label {
            max-width: 183px !important;
          }
          .width-25 {
            width: 24.3% !important;
          }
        }
      }
    }
  }
}

.isFilterSelected {
  background: #bdfff4;
  color: #1a5e83;
  font-weight: 500;
}

.hover-row:hover {
  background: #dbfff9;
  color: #1a5e83;
}

.table-pcd {
  .table {
    thead {
      z-index: 100 !important;
    }
  }
}