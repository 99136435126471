@import "../tooltip/variable";
.input-number-addon {
    background-color: transparent;
    color: var(--bs-gray);       

    &--focus {
        background-color: var(--kt-input-focus-bg-custom);
    }
}
.customs-input {
    height: 29px;
    font-size: $default-font-size;
    border-color: $color-border-gray !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    padding: 1px 4px;

    &:disabled {
        border-color: $color-border-gray !important;
    }
    &.ps-29px{
        padding-right: 29px !important;
    }
    
}